import { ActivityLogger } from "./activity-logger.js";

const rdy = (fn) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', fn);
        return;
    }
    fn()
}

function initLogging() {
    const logger = new ActivityLogger({
        ajaxUrlPageview: "/activity/pageview",
        ajaxUrlEvent: "/activity/event",
    });

    ActivityLogger.onPageLoaded(
        () => setTimeout(() => logger.trackPageview())
    );

    (window as any).backendTrackPageview = function (url=null, title=null) {
        logger.trackPageview(url, title);
    };

    (window as any).backendTrackEvent = function (category, action, name, val=null) {
        logger.trackEvent(category, action, name, val);
    };
}


rdy(() => {
    initLogging();
})